(function() {
    'use strict';

    angular.module('adminApp')
        .factory("instructionsAdmin", InstructionsAdminFactory);

    InstructionsAdminFactory.$inject = ['routesConstant'];

    function InstructionsAdminFactory(routesConstant) {
        var pages = {};

        (function initAdminPages(pages, ad) {
            pages[ad.ORGANIZATIONS.stateName] = {
                title: "Administrator Dashboard - Organization",
                type: "Administrator Dashboard - Organization",
                instructions: 'Available Actions – Admin Dashboard Profile',
                actions: [
                    {action: "Edit", icon: "pencil", instructions: "Edit Organization Details/Licenses"},
                    {action: "Disable", icon: "ban", instructions: "Suspend Organization"},
                    {action: "Enable", icon: "undo", instructions: "Restore Organization"},
                    {action: "Delete", icon: "trash", instructions: "Delete Organization (permanent)"}
                ]
            };

            pages[ad.ORGANIZATIONS_VIEW.stateName] = {
                title: "Organization View",
                type: "Organization View",
                instructions: 'Help is being defined.',
                actions: [
                    {icon: "plus", instructions: ""}
                ]
            };

            pages[ad.PROPERTIES.stateName] = {
                title: "Administrator Dashboard - Properties",
                type: "Administrator Dashboard - Properties",
                instructions: 'Help is being defined.',
                actions: []
            };

            pages[ad.SOFTWARE.stateName] = {
                title: "Administrator Dashboard - Software",
                type: "Administrator Dashboard - Software",
                instructions: 'Help is being defined.',
                actions: []
            };

            pages[ad.USERS.stateName] = {
                title: "Administrator Dashboard - Manage Users",
                type: "Administrator Dashboard - Manage Users",
                instructions: 'Administrator Dashboard - Manage Users',
                actions: [
                    {action: "Add", icon: "plus", instructions: "Add a new system admin user"},
                    {action: "Edit", icon: "pencil", instructions: "Edit a system admin user"},
                    {action: "Delete", icon: "trash", instructions: "Delete a system admin user"},
                    {action: "More", icon: "arrow-down", instructions: "Load more users"},
                    {action: "Export Filtered Users as CSV", icon: "download", instructions: "Export Filtered Users as CSV"},
                    {action: "Export All Users as CSV (in background)", icon: "arrow-circle-down", instructions: "Export All Users as CSV (in background)"},
                    {action: "Filter", icon: "filter", instructions: "Filter users based on organization or system admin roles"},
                ]
            };

            pages[ad.NOTIFICATION_REGISTRATION.stateName] = {
                title: "Administrator Dashboard - Notification Registration",
                type: "Administrator Dashboard - Notification Registration",
                instructions: 'Help is being defined.'
            };

        })(pages, routesConstant.ADMIN);

        angular.merge(pages, {
            'dashboard.demoSetup': {
                title: "Administrator Dashboard - Demo Setup",
                type: "Administrator Dashboard - Demo Setup",
                instructions: 'Help is being defined.',
                actions: []
            }
        });

        return pages;
    }

})();
